@import "../../styles/defaults";

.legacy_ordermodal_bookmark_add_button_outer .invisible_container .bookmark_add_button {
    width: 100%;
    border: none;
    transition: background-color 0.3s;
    border-radius: 50px;
    cursor: pointer;
    height: $default-button-size;
    background-color: $dp-light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $default-font-weight;
    letter-spacing: $default-letter-spacing;
    font-size: 12px;
    user-select: none;

    span {
        line-height: $default-button-size;
    }

    &:hover {
        background-color: $dp-mid-grey;
        transition: background-color 0.3s;
    }
}

.bookmark_add_button {
    svg {
        margin-right: 12px;
        fill: $dp-pdp-black;
    }

    span {
        color: $dp-pdp-black;
    }
}

.bookmark_add_button_icon {
    background-color: $dp-white;
    border: 1px solid $dp-mid-grey;
    border-radius: $default-border-radius;
    cursor: pointer;
    align-items: center;
    height: $default-button-size;
    width: $default-button-size;
    transition: border-color 0.3s;

    &:hover {
        border-color: $dp-pdp-black;
    }

    svg {
        vertical-align: sub;
        height: 20px;
        fill: $dp-black;
    }
}

:global(.mobileshop) {
    .legacy_ordermodal_bookmark_add_button_outer {
        .invisible_container {
            margin-top: initial;
            padding: 0 5px;

            .bookmark_add_button {
                margin: unset;
                width: 100%;
                color: $dp-pdp-black;
                border: unset;
                height: $default-button-size;
                font-weight: $default-font-weight;
            }
        }
    }
}

:global(.mobileshop.RedesignOrderModal ) {
    .legacy_ordermodal_bookmark_add_button_outer {
        .invisible_container .bookmark_add_button {
            width: auto;
        }
    }
}

:global(:not(.mobileshop) .scope-order-modal) {
    .bookmark_add_button_icon span {
        display: none;
    }
}

:global(.fas-categorypage-scope) {
    .bookmark_add_button_icon {
        height: 36px;
        width: 36px;
        border: 0;

        span {
            display: none;
        }
    }
}

:global(.fas-categorypage-scope) {
    .bookmark_add_button {
        height: 36px;
        width: 36px;
        border: 0;
        background-color: $dp-white;
        border-radius: 50px;
        padding: 0;

        span {
            display: none;
        }

        svg {
            margin: 0;
            vertical-align: sub;
            height: 20px;
        }
    }
}
