$breakpoint-mobile-extra-small: 320px;
$breakpoint-mobile-min-width: 768px;
$breakpoint-tablet-min-width: 1024px;
$breakpoint-desktop-min-width: 1200px;

$dp-red: #e30613;
$dp-light-red: #ff0818;
$dp-eyeCatcher-new: #f0b400;
$dp-green: #70a848;
$dp-orange: orange;
$dp-black: #1e1e1e;
$dp-pdp-black: #000000;
$dp-white: white;
$dp-Gainsboro: #e6e6e6;
$dp-boxshadow: #d6d6f5;
$dp-dropwodn-boxshadow: rgba(0, 0, 0, 0.2);
$dp-light-grey: #F0F1F1;
$dp-grey: #d6d6d6;
$dp-dark-grey: #787878;
$dp-grey-inactive: #bbbbbb;
$dp-mid-grey: #d3d4d4;
$dp-dark-grey: #999999;

$default-border: 2px solid $dp-light-grey;
$default-border-radius: 50px;

$default-spacing: 8px;
$bookmark-modal-left-right-padding: 30px;

$default-font-size: 13px;
$heading-font-size: 17px;
$default-font-weight: 500;

$default-button-size: 48px;
$default-letter-spacing: 0.3px;

// exports js variables

$transition_duration: 300ms;

:export {
    breakpointMobileExtraSmall: $breakpoint-mobile-extra-small;
    breakpointMobileMinWidth: $breakpoint-mobile-min-width;
    breakpointTabletMinWidth: $breakpoint-tablet-min-width;
    breakpointDesktopMinWidth: $breakpoint-desktop-min-width;

    dpRed: $dp-red;
    dpGreen: $dp-green;
    dpOrange: $dp-orange;
    dpBlack: $dp-black;
    dpWhite: $dp-white;
    dpGainsboro: $dp-Gainsboro;
    dpBoxshadow: $dp-boxshadow;
    dpDropwodnBoxshadow: $dp-dropwodn-boxshadow;
    dpLightGrey: $dp-light-grey;
    dpGrey: $dp-grey;
    dpDarkGrey: $dp-dark-grey;
    dpGreyInactive: $dp-grey-inactive;

    defaultSpacing: $default-spacing;

    defaultFontSize: $default-font-size;
    headingFontSize: $heading-font-size;
}
