@import "../../styles/defaults";

:global {
    body.bookmark_scroll_lock {
        overflow: hidden !important;
        position: fixed !important;

        &.parallax, &.parallax2 {
            position: static !important;
        }
    }
}

.bookmark_modal {
    position: fixed;
    // breadcrumb has zIndex of 1040 and header has 1050, ordermodal has 1065
    z-index: 1066;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: default;

    //these are needed to reset some inherited styles
    line-height: normal;
    font-size: 13px;
    color: #000000;
    white-space: initial;
    text-align: initial;
    font-weight: initial;

    .bookmark_modal_container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        .bookmark_modal_content {
            background-color: $dp-white;
            padding: 20px 40px;
            border: solid grey 1px;
            max-height: 430px;
            width: 500px;
            line-height: 19px;
            letter-spacing: $default-letter-spacing;

            .bookmark_modal_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid $dp-mid-grey 1px;
                padding-bottom: 16px;
                font-weight: bold;
                font-size: 17px;
                text-transform: uppercase;

                .bookmark_modal_title_text {
                    color: $dp-black;
                }

                .close_button {
                    cursor: pointer;
                    display: flex;

                    svg {
                        .svg_border {
                            fill: $dp-mid-grey;
                            transition: fill $transition_duration;
                        }

                        .svg_cross {
                            fill: $dp-black;
                        }
                    }

                    &:hover {
                        svg .svg_border {
                            transition: fill $transition_duration;
                            fill: $dp-black;
                        }
                    }
                }
            }

            .bookmark_modal_middle {
                border-bottom: solid $dp-mid-grey 1px;
                display: flex;
                align-items: center;
                max-height: 300px;
                overflow: hidden;
                position: relative;
                padding-top: 16px;

                .bookmark_modal_text_box {
                    margin-left: 50px;
                    width: 220px;

                    .bookmark_modal_text_bold {
                        font-weight: bold;
                        font-size: $default-font-size;
                        color: $dp-black;
                    }

                    .bookmark_modal_text_regular {
                        font-size: $default-font-size;
                        color: $dp-dark-grey;

                        > label {
                            display: inline-block;
                            font-size: $default-font-size;
                            color: $dp-dark-grey;
                            font-weight: normal;

                            &.color_capital_letter {
                                text-transform: capitalize;
                            }
                        }
                    }
                }

                .image_container {
                    width: 250px;
                    height: inherit;
                    overflow: hidden;
                    display: flex;
                    margin: 20px 0;

                    .bookmark_image {
                        margin: auto;
                        max-height: 300px;
                    }
                }
            }

            .bookmark_modal_footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 20px;

                > div {
                    border-radius: 50px;
                    border-style: solid;
                    cursor: pointer;
                    padding: 10px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: $default-button-size;
                    line-height: 19px;
                    letter-spacing: $default-letter-spacing;
                    font-size: $default-font-size;
                    flex-grow: 1;

                    svg {
                        margin-right: 12px;
                    }
                }

                .to_bookmarked_items_button {
                    background-color: $dp-light-grey;
                    transition: background-color $transition_duration;
                    margin-right: 10px;
                    font-weight: $default-font-weight;
                    border: none;
                    color: $dp-pdp-black;
                    fill: $dp-pdp-black;

                    &:hover {
                        transition: background-color $transition_duration;
                        background-color: $dp-mid-grey;
                    }
                }

                .continue_shopping_button {
                    color: $dp-white;
                    border: none;
                    transition: background-color $transition_duration;
                    font-weight: $default-font-weight;
                    background-color: $dp-red;

                    &:hover {
                        transition: background-color $transition_duration;
                        background-color: $dp-light-red;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $breakpoint-mobile-min-width - 1px) {
        .bookmark_modal_container {
            padding-top: 0;

            .bookmark_modal_content {
                width: calc(100% - 2em);
                max-height: 80vh;
                padding-top: 5px;
                padding-bottom: 5px;

                .bookmark_modal_middle {
                    height: 250px;

                    .image_container {
                        position: unset;

                        .bookmark_image {
                            height: unset;
                            left: -90px;
                        }
                    }

                    .bookmark_modal_text_box {
                        margin-left: 0px;
                        background-color: white;
                        z-index: 1;
                        padding: 20px;
                        width: 250px;
                        height: 220px;
                    }

                    label {
                        margin: 0 3px 0 0;
                    }
                }

                .bookmark_modal_footer {
                    padding: 15px 0px 10px 0px;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-mobile-min-width) {
        .bookmark_modal_container {
            .bookmark_modal_content {
                margin: auto;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop-min-width) {
        .bookmark_modal_container {
            align-items: center;

            .bookmark_modal_content {
                max-height: 430px;
                width: 500px;
            }
        }
    }
}
