.pdp-invisible_container {
    .has-focus {
        &.has-focus-mouse {
            outline:0 !important;
        }
    }
}

.disable_tabNav_styles {
    div[focusable],
    button[focusable],
    a[focusable] {
        &:focus {
            outline:0 !important;                
        }
    }
}
