.legacy_ordermodal_bookmark_add_button_outer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    padding: initial;

    &:hover {
        display: flex;
        padding: initial;
    }

    .invisible_container {
        width: 100%;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    &.legacy_web_button {
        .bookmark_add_button {
            border: none;
        }
    }
}

:global(.mobileshop){
    .legacy_ordermodal_bookmark_add_button_outer {
        .invisible_container .bookmark_add_button {
                font-size: 14px;
        }
    }
}
